import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const NotFound = ({ errorCode = 404, errorMessage }) => {
  const { t } = useTranslation();
  return (
    <section>
      <p style={{ textAlign: 'center' }}>{`${errorCode} ${errorMessage || t('Page not found', { ns: '404' })}`}</p>
    </section>
  );
};

export default NotFound;

export const query = graphql`
  query {
    locales: allLocale{
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
